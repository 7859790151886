import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssetsTreeRemoteConstant } from '../../constants';
import {
    AssetChildItemContract
} from '../../contracts';
import { RemoteClientService } from '@comMobileApp/storage/common/services';

@Injectable()
export class AssetsTreeStorageRemoteProvider {

    constructor(
        private remoteClientService: RemoteClientService
    ) {
    }

    public getAssetChildCollection(assetId: string, parentCustomerId: string, assetType: string): Observable<Array<AssetChildItemContract>> {
        const params = { assetId, parentCustomerId, assetType };
        if (!parentCustomerId) {
            delete params.parentCustomerId;
        }
        const data = this.remoteClientService.get(AssetsTreeRemoteConstant.ASSET_CHILD_COLLECTION, params);
        return data;
    }

    public getAssetRoot(search: string): Observable<Array<AssetChildItemContract>> {
        const params = search ? { search } : {};
        const data = this.remoteClientService.get(AssetsTreeRemoteConstant.ASSET_TREE, params);
        return data;
    }
}
