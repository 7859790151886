import { environment } from "@environments/environment";

export class AssetsTreeRemoteConstant {

    public static ASSETS_TREE_SELECTOR = environment.apiWebPrefix + 'private/assetManager/distributorTree/:customerId';
    public static ASSET_CHILD_COLLECTION = environment.apiWebPrefix + 'private/assetManager/child';
    public static ASSET_TREE = environment.apiWebPrefix + 'private/assetManager';
    public static FLOOR = environment.apiWebPrefix + 'private/assetManager/floors/:id';
    public static FLOOR_IMPORT = environment.apiWebPrefix + 'private/floorEditor/:id/commissioning';
    public static FLOOR_IMPORT_REPORT = environment.apiWebPrefix + 'private/floorEditor/:floorId/commissioning';
    public static FLOOR_EXPORT = environment.apiWebPrefix + 'private/floorEditor/:id/export';
    public static BUILDING = environment.apiWebPrefix + 'private/assetManager/buildings/:id';
    public static SITE = environment.apiWebPrefix + 'private/assetManager/sites/:id';
    public static FLOOR_IMAGE = environment.apiWebPrefix + 'private/assetManager/floors/:floorId/image';
    public static CUSTOMERS = environment.apiWebPrefix + 'private/assetManager/customers';
    public static CUSTOMER = environment.apiWebPrefix + 'private/assetManager/customers/:id';
    public static BUILDINGS_BY_CUSTOMER = environment.apiWebPrefix + 'private/assetManager/customers/:customerId/buildings';
    public static SITES_BY_CUSTOMER = environment.apiWebPrefix + 'private/assetManager/customers/:customerId/sites';
    public static UPDATE_MULTI_FLOOR_SETTINGS = environment.apiWebPrefix + 'private/assetManager/floors/settings';
    public static ASSETS_BREADCRUMBS = environment.apiWebPrefix + 'private/assetManager/breadcrumb';
    public static FLOOR_CHILD_INFO = environment.apiWebPrefix + 'private/assetManager/floors/:floorId/info';
    public static BUILDING_CHILD_INFO = environment.apiWebPrefix + 'private/assetManager/buildings/:buildingId/info';
    public static SITE_CHILD_INFO = environment.apiWebPrefix + 'private/assetManager/sites/:siteId/info';
    public static CUSTOMER_CHILD_INFO = environment.apiWebPrefix + 'private/assetManager/customers/:customerId/info';
    public static ASSETS_TRASH_NUMBER = environment.apiWebPrefix + 'private/assetManager/trash/number';
    public static TRASH_ASSET_TREE = environment.apiWebPrefix + 'private/assetManager/trash';
    public static TRASH_ASSET_DELETE = environment.apiWebPrefix + 'private/assetManager/trash/delete';
    public static TRASH_ASSET_RECOVER = environment.apiWebPrefix + 'private/assetManager/trash/recover';
}
