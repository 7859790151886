
import {
  ApplicationConfig,
  importProvidersFrom
} from '@angular/core';
import { GlobalRes } from '@comMobileCore/i18n';
import {
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '@environments/environment';
import { AssetsTreeStorageRemoteProvider } from '@comMobileApp/storage/assets/providers';
import { RemoteClientService } from '@comMobileApp/storage/common/services';
import {
  ErrorHandlerService,
  ProgressIndicatorsService,
  QrCodeScannerService
} from '@comMobileApp/shared/services';
import { UserModel } from '@comMobileApp/core/user';
import {
  DevicesCacheProvider,
  DevicesStorageRemoteProvider
} from '@comMobileApp/storage/devices';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  AreasCacheProvider,
  AreasStorageRemoteProvider
} from '@comMobileApp/storage/areas';
import {
  FLOOR_PLAN_STORAGE_TOKEN,
  FabricProvider,
  FloorMapStorageModel,
  FloorModel,
  FloorPlanModel,
  PlanViewProvider
} from '@comMobileApp/core/floorPlan';
import { FloorStorageRemoteProvider } from '@comMobileApp/storage/floor';
import { AuthenticationProvider } from '@comMobileApp/core/auth';

const HttpLoaderFactory = (httpClient: HttpClient): any => new TranslateHttpLoader(httpClient, 'comMobileAppAssets/i18n/', '.json?v=' + environment.version);

export const ComMobileAppConfig: ApplicationConfig = {
  providers: [
    GlobalRes,
    UserModel,
    RemoteClientService,
    ProgressIndicatorsService,
    ErrorHandlerService,
    DevicesStorageRemoteProvider,
    AreasStorageRemoteProvider,
    AssetsTreeStorageRemoteProvider,
    FabricProvider,
    PlanViewProvider,
    AuthenticationProvider,
    FloorModel,
    FloorPlanModel,
    FloorStorageRemoteProvider,
    QrCodeScannerService,
    DevicesCacheProvider,
    AreasCacheProvider,
    { provide: FLOOR_PLAN_STORAGE_TOKEN, useClass: FloorMapStorageModel },
    importProvidersFrom(TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }))
  ]
}

export const AppSharedModules = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule
]
