import { UserContract } from '@comMobileApp/storage/user';
import {
    CustomerTypeEnum,
    RolesEnum
} from '../enums';
import { Injectable } from '@angular/core';

@Injectable()
export class UserModel {

    private pId: string;
    public get id(): string {
        return this.pId;
    }

    private pCustomerId: string;
    public get customerId(): string {
        return this.pCustomerId;
    }

    private pCustomerType: CustomerTypeEnum;
    public get customerType(): CustomerTypeEnum {
        return this.pCustomerType;
    }

    private pCustomerName: string;
    public get customerName(): string {
        return this.pCustomerName;
    }

    private pRole: RolesEnum;
    public get role(): RolesEnum {
        return this.pRole;
    }

    private pPrincipalType: string;
    public get principalType(): string {
        return this.pPrincipalType;
    }

    private pFirstName: string;
    public get firstName(): string {
        return this.pFirstName;
    }

    private pLastName: string;
    public get lastName(): string {
        return this.pLastName;
    }

    // private pSettings: UserSettingsModel;
    // public get settings(): UserSettingsModel {
    //     return this.pSettings;
    // }

    private pSsoCustomer: boolean;
    public get ssoCustomer(): boolean {
        return this.pSsoCustomer;
    }

    constructor(
        ) {
    }

    public applyContract(contract: UserContract): void {
        this.pId = contract.userId;
        this.pCustomerId = contract.customerId;
        this.pCustomerName = contract.customerName;
        this.pRole = contract.role as RolesEnum;
        this.pPrincipalType = contract.principalType;
        this.pFirstName = contract.firstName;
        this.pLastName = contract.lastName;
        this.pSsoCustomer = contract.ssoCustomer;
        this.pCustomerType = contract.customerType as CustomerTypeEnum;
    }

}
